@import 'tailwindcss/base';

@layer base {
  @font-face {
    font-family: "Ubuntu";
    src: url("../assets/font/Ubuntu/Ubuntu-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: "Ubuntu";
    src: url("../assets/font/Ubuntu/Ubuntu-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: "Ubuntu";
    src: url("../assets/font/Ubuntu/Ubuntu-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: "Ubuntu";
    src: url("../assets/font/Ubuntu/Ubuntu-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    text-rendering: optimizeLegibility;
  }


  @font-face {
    font-family: "Sofia Sans";
    src: url("../assets/font/SofiaSans/SofiaSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: "Sofia Sans";
    src: url("../assets/font/SofiaSans/SofiaSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: "Sofia Sans";
    src: url("../assets/font/SofiaSans/SofiaSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-family: "Sofia Sans";
    src: url("../assets/font/SofiaSans/SofiaSans-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    text-rendering: optimizeLegibility;
  }
}



:root {
  -webkit-font-smoothing: antialiased;
  --ion-font-family: theme('fontFamily.ubuntu'), sans-serif;
  --ion-default-font: theme('fontFamily.ubuntu'), sans-serif;
}
