.no-backdrop-shadow {
  &::part(backdrop) {
    background: transparent !important;
  }
}

// Loader
.custom-loading {
  background-color: rgba(0, 0, 0, 0.25) !important;

  .loading-wrapper {
    background: transparent !important;
    box-shadow: none !important;

    ion-spinner {
      color: #ffffff;
      width: 80px;
      height: 80px;
    }
  }
}

.auth-page__footer,
.auth-page__header {
  ion-toolbar {
    --background: #ffffff;
  }
}

.page-toolbar {
  --background: #f0efef;
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-top: 16px;
  --padding-bottom: 16px;

  &.modal-toolbar {
    --padding-start: 4px;
    --padding-end: 4px;
    --padding-top: 8px;
    --padding-bottom: 8px;
  }
  &.toolbar-white {
    --background: #ffffff;
  }
}
ion-content.content-bg {
  --background: #f0efef;
}

// Buttons
ion-button {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: none;
  --border-radius: 5px;
  height: 58px;

  &.has-box-shadow {
    --box-shadow: 1px 3px 4px #9e9e9e;
  }
  &.no-shadow {
    --box-shadow: none;
  }
}

// Modal Utilities
ion-modal {
  &::part(backdrop) {
    opacity: 0.5;
    & + .modal-shadow {
      box-shadow: none !important;
    }
  }
  &:not(.ion-datetime-modal, .delete-account-modal, .api-key-modal) {
    @screen lg {
      @apply justify-end;
    }
    &::part(content) {
      @apply md:h-full md:max-w-[375px];
    }
  }
}
.auto-grow-modal {
  --background: transparent;
  --backdrop-opacity: 0.7 !important;
  .auto-grow-modal__container {
    position: absolute;
    bottom: 0;
    max-height: calc(100vh - 80px);
    overflow: auto;
    // border-radius: 20px 20px 0px 0px;
    background: var(--ion-color-pure-white);
  }
}
.auto-grow-modal::part(backdrop),
.ion-datetime-modal::part(backdrop) {
  background: rgba(0, 0, 0, 0.5);
  --backdrop-opacity: 1;
}
.custom-blur-modal {
  --background: rgba(0, 0, 0, 0.25);
  --width: calc(100vw - 24px);
  --min-width: calc(100vw - 24px);
  --height: fit-content;
  --border-radius: 30px;
  backdrop-filter: blur(15px);
  @apply justify-center;
  @screen lg {
    --width: 345px;
    --min-width: 400px;
  }
}

// Popovers
.select-popover {
  --max-height: 265px;
  &::part(arrow) {
    @apply hidden;
  }
  &::part(content) {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    @apply bg-white rounded -mt-[22px];
  }
}

// Buttons
ion-button.close-btn {
  width: 32px !important;
  height: 32px !important;
  --padding-start: 4px !important;
  --padding-end: 4px !important;
  --padding-top: 4px !important;
  --padding-bottom: 4px !important;
  ion-icon {
    font-size: 24px !important;
  }
}

// Table
.custom-table {
  th {
    @apply whitespace-nowrap px-2 pt-[15px] pb-2.5 bg-primary text-xs font-semibold text-white sticky z-30 top-0;
  }
  tr {
    td {
      @apply whitespace-nowrap pt-[17px] pb-[7px] text-[#464E5F] text-sm border-b border-b-[#E7E7E7];
      &:not(:first-child) {
        @apply px-2 text-center;
      }
    }
  }
}
