/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

@import "theme/font.scss";
@import "theme/utils.scss";
@import "theme/custom-input.scss";
@import "theme/custom-slider.scss";

@import "swiper/scss";
@import "swiper/scss/autoplay";
@import "swiper/scss/pagination";

ion-grid {
  --ion-grid-padding: 0;
}
ion-col {
  padding-top: 0;
  padding-bottom: 0;
  // --ion-grid-column-padding: 0;
}

// Loading controller helper
.mt-loader__ctrl {
  --background: #fff9e7;
  --spinner-color: #5c5f60;
  color: #006633;
  .loading-wrapper {
    background: #fff9e7;
    flex-direction: column;
  }
}

// Backgrounds
.ion-bg__content {
  --background: #e5e5e5;
}
// #f4f7fb
.bg-mt {
  background-color: #006633;
}
.ion-bg-mt {
  --background: #006633;
}


// Buttons
.mt-btn {
  --padding-start: 16px;
  --padding-end: 16px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-transform: none;
}
.btn-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
// Custom Alert
.mt-alert,
.logout-alert {
  --min-width: 280px !important;
  .alert-wrapper {
    padding: 12px 0 14px;
  }
  .alert-head {
    padding-top: 6px;
  }
  .only {
    max-width: 100px;
    margin: auto !important;
  }
}

.mt-alert {
  .alert-title {
    // font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 0;
  }
  .alert-message {
    font-size: 14px;
    text-align: center;
    // letter-spacing: .02em;
    // color: #B3B3B3;
    padding-bottom: 7px;
  }
  .alert-button-group {
    padding: 10px;
  }
  .alert-button {
    border: none !important;
    border-radius: 4px;
    min-width: 47%;
  }
  .mt-btn {
    background-color: #006633 !important;
    color: #ffffff;
  }
  .alert-button-inner {
    justify-content: center !important;
  }
}
.logout-alert {
  .alert-button {
    border: none !important;
    border-radius: 4px;
    min-width: 47%;
  }
  .mt-btn {
    background-color: #950000 !important;
    color: #ffffff;
  }
}
