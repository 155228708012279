.notice-slider {
  height: 100%;
}

.swiper-pagination {
  .swiper-pagination-bullet {
    background: #cefffd !important;
  }
  .swiper-pagination-bullet-active {
    background: #006633 !important;
    opacity: 1;
  }
  &.swiper-pagination-fraction {
    padding: 2px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px 0px 0px 0px;
    width: fit-content;
    bottom: 0;
    right: 0;
    left: unset;
    color: #ffffff;
    font-size: 10px;
    line-height: 11px;
  }
}

.onboard-swiper {
  .swiper-pagination {
    bottom: 0 !important;
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid #0A4605;
      background: #ffffff !important;
    }

    .swiper-pagination-bullet-active {
      background: var(--ion-color-primary) !important;
    }
  }
}
