ion-item {
  &.input-item {
    cursor: pointer;
    --padding-start: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;

    // .input-label {
    //   position: absolute;
    //   top: 7px;
    //   left: 20px;
    //   z-index: 999;
    //   ion-label {
    //     font-weight: 800 !important;
    //     font-size: 9px !important;
    //     line-height: 15px !important;
    //     color: #a8a8a9 !important;
    //     margin-left: 5px !important;
    //   }
    // }
    ion-label {
      transform: scale(1) !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 18px !important;
      color: var(--ion-color-primary) !important;
      margin-bottom: 12px !important;
    }

    .input-icon-left {
      position: absolute;
      left: 0.938rem;
      z-index: 999;
    }

    .input-icon-right {
      position: absolute;
      right: 13px;
      z-index: 999;
    }

    .item-input {
      @apply bg-white border border-[#9e9e9e] rounded-[5px] font-light text-sm text-black;
      padding: 18px 10px !important;
      max-height: 52px !important;

      --placeholder-color: #9e9e9e;
      --padding-start: 0;
      --padding-top: 0;
      --padding-end: 0;
      --padding-bottom: 0;

      &[ng-reflect-readonly="true"] {
        @apply bg-[#E7E7E7];
      }
    }

    &.item-has-value {
      &.ion-dirty.ion-invalid {
        .item-input {
          border: 1px solid var(--ion-color-danger);
          background: #fff5f6;
        }
      }
    }

    &.item-has-value {
      .item-input:not([readonly="true"]) {
        // border: 1px solid var(--ion-color-primary);
        // background: #ffffff;
      }
    }
  }
  &.select-item {
    cursor: pointer;
    --padding-start: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    &::part(native) {
      @apply px-3 border border-[#9e9e9e] rounded-[5px] h-[52px];
    }
    &.has-value {
      &::part(native) {
        @apply border-primary;
      }
    }
  }
}

.item-input.select-disabled {
  background: #e7e7e7 !important;
  @apply opacity-100;
}

.item-interactive-disabled:not(.item-multiple-inputs) .input-label {
  @apply opacity-100;
}

ion-datetime {
  @apply text-primary;
}
ion-datetime-button {
  @apply opacity-0 h-[24px];

  &::part(native) {
    @apply w-full h-full;
  }
}
